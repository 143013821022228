import { getSitedefinition } from 'kmf-sitedefinition';

const { brand, jurisdiction, locale } = getSitedefinition();

export default async function sitemap({ basePath, routeCache }) {
  const gamePath = routeCache.get('play-game')?.path;
  const cmsPath = `/onecms/proxy/content/${gamePath}`;
  const resp = await fetch(`${basePath}${cmsPath}`);
  const json = await resp.json();
  const contract =
    json.aspects?.contentData?.data?.slots?.mainSlot?.elements?.[0];

  const { licenseCode } = contract;
  const url = new URL(
    '/game-library-rest-api/games/getGamesByMarketAndDevice.json',
    basePath
  );
  url.searchParams.set('brand', brand);
  url.searchParams.set('jurisdiction', jurisdiction);
  url.searchParams.set('locale', locale);
  if (licenseCode) {
    url.searchParams.set('licenses', licenseCode);
  }
  const games = await (await fetch(url)).json();

  return {
    type: 'routeRef',
    data: Object.values(games.games).map((g) => ({
      routeId: 'play-game',
      params: { pathSegment: g.pathSegment },
    })),
  };
}
